import { Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import type { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import NextLink from "next/link";

const Custom404: NextPage = () => {
  const { t } = useTranslation("common");
  return (
    <SimpleGrid columns={2} columnGap="4rem" height="100vh">
      <Flex position="relative">
        <Image
          src="/undraw_404.svg"
          alt={t("work-in-progress")}
          fill
          style={{
            maxWidth: "400px",
            margin: "0 auto",
          }}
        />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <Flex flexDirection="column" justifyContent="center" gap={6}>
          <Heading as="h1">{t("not-found")}</Heading>
          <Text>{t("not-found-description")}</Text>
          <NextLink href="/" legacyBehavior passHref>
            <Button maxW="200px">{t("go-back-to-homepage")}</Button>
          </NextLink>
        </Flex>
      </Flex>
    </SimpleGrid>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

export default Custom404;
